/* eslint-disable react/prop-types */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import './TeamMemberCard.css'; // Assuming custom styles are here

const TeamMemberCard = ({
  image,
  name,
  position,
  phoneNumber,
  email,
  socials,
}) => {
  return (
    <div className="team-card group relative block bg-black m-3">
      <img
        alt={name}
        src={image}
        className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
      />
      <div className="relative p-4 sm:p-6 lg:p-8">
        <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
          {position}
        </p>
        <p className="text-xl font-bold text-white sm:text-2xl">{name}</p>

        <div className="mt-32 sm:mt-48 lg:mt-64">
          <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
            <p className="text-sm text-white">
              <FontAwesomeIcon icon={faPhone} /> {phoneNumber} <br />
              <FontAwesomeIcon icon={faEnvelope} /> {email}
            </p>
            <div className="socials mt-2">
              {socials?.facebook && (
                <a href={socials.facebook} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} size="lg" className="text-white mx-1" />
                </a>
              )}
              {socials?.twitter && (
                <a href={socials.twitter} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} size="lg" className="text-white mx-1" />
                </a>
              )}
              {socials?.instagram && (
                <a href={socials.instagram} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} size="lg" className="text-white mx-1" />
                </a>
              )}
              {socials?.linkedin && (
                <a href={socials.linkedin} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} size="lg" className="text-white mx-1" />
                </a>
              )}
              {socials?.youtube && (
                <a href={socials.youtube} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faYoutube} size="lg" className="text-white mx-1" />
                </a>
              )}
              {socials?.x && (
                <a href={socials.x} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faXTwitter} size="lg" className="text-white mx-1" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCard;
