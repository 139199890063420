import React from "react";
import PageBanner from "../layouts/PageBanner";

import bg from "../assets/images/banner/bnr1.jpg";
import shape1 from "../assets/images/pattern/shape1.png";
import shape3 from "../assets/images/pattern/shape3.png";
import shape5 from "../assets/images/pattern/shape5.png";
import shape6 from "../assets/images/pattern/shape6.png";
import shape7 from "../assets/images/pattern/shape7.png";
import team1 from "../assets/images/team/team1.jpg";
import team2 from "../assets/images/team/team2.jpg";
import team3 from "../assets/images/team/team3.jpg";
import team4 from "../assets/images/team/Alhaji Ibrahim Mohammed.jpeg";
import team5 from "../assets/images/team/Aminu Yakubu Nassarawa.jpeg";
import team6 from "../assets/images/team/BALA  ABUBAKAR.jpeg";
import team7 from "../assets/images/team/WhatsApp Image 2024-10-12 at 11.48.03 (3).jpeg";
import team8 from "../assets/images/team/ISIYAKU M  YAKUB.jpeg";
import team9 from "../assets/images/team/WhatsApp Image 2024-10-12 at 11.48.04.jpeg";
import team10 from "../assets/images/team/Mohammed Bello Ahmed.jpeg";
import team11 from "../assets/images/team/Mohammed Kamilu Abdullahi.jpeg";
import team12 from "../assets/images/team/SHEHU MAGAGO.jpeg";
import team13 from "../assets/images/team/Shehu Yakubu Usman.jpeg";
import TeamMemberCard from "./TeamMemberCard";

const teamMembers = [
  {
    image: team13,
    name: "Shehu Yakubu Usman",
    phoneNumber: "07033418329",
    email: "yakubu227@gmail.com",
    position: "Director Admin and Human Resources",
    // title: "HOD",
  },
  {
    image: team11,
    name: "Mohammed Kamilu Abdullahi",
    phoneNumber: "08036026171",
    email: "tsamiya68@gmail.com",
    position: "Director Finance and Account",
    // title: "HOD",
  },
    {
    image: team2,
    name: "Baba Umar",
    phoneNumber: "08031318384",
    email: "babaumar273@gmail.com",
    position: "HOD HEALTH",
    // title: "HOD",
  },
  
  {
    image: team1,
    name: "Abubakar Ibrahim Muhammad",
    phoneNumber: "08065244707",
    email: "abnan1163@gmail.com",
    position: "HOD INFORMATION/ICT",
    // title: "HOD",
  },

  {
    image: team3,
    name: "Ali Yushau Sulaiman",
    phoneNumber: "08069651301",
    email: "alyasausulaiman@gmail.com",
    position: "HOD EDU",
    // title: "HOD",
  },
  {
    image: team9,
    name: "Jamilu  Dan Azumi",
    phoneNumber: "07034061961",
    email: "danazumijamilu2@gmail.com",
    position: "H.O.D. Child protection",
    // title: "HOD",
  },
  {
    image: team4,
    name: "Alhaji Ibrahim Mohammed",
    phoneNumber: "08036060994",
    email: "ibrahimmoh'd0402@gmail.com",
    position: "C.S.O",
    // title: "HOD",
  },
  {
    image: team6,
    name: "BALA  ABUBAKAR",
    phoneNumber: "09025795655",
    email: "abubakarnng7@gmail.com",
    position: "H.O.D Shelter",
    // title: "HOD",
  },
  {
    image: team10,
    name: "Mohammed Bello Ahmed",
    phoneNumber: "07030301443",
    email: " belloahmed7@gmail.com",
    position: "C.I.A (chief internal Auditor)",
    // title: "HOD",
  },
  {
    image: team5,
    name: "Aminu Yakubu Nassarawa",
    phoneNumber: "08023994809",
    position: "H.O.D. HENS/PSYCHOSOCIAL",
    // title: "HOD",
  },
  {
    image: team12,
    name: "SHEHU MAGOGO",
    phoneNumber: "08036559768",
    position: "S.O  (staff officer",
    // title: "HOD",
  },
  {
    image: team8,
    name: "ISIYAKU M Tukur",
    phoneNumber: "08067771057",
    // email: "alyasausulaiman@gmail.com",
    position: "ADMIN OFFICER",
    // title: "HOD",
  },

  {
    image: team7,
    name: "Fatima Muhammad Tata",
    phoneNumber: "08038362509",
    email: "Fatimamuhammadtata01@gmail.com",
    position: "HOD FOOD AND NUTRITION",
    // title: "HOD",
  },

  // Add more team members as needed
];

const Team = () => {
  return (
    <div className="page-content bg-white">
      <PageBanner
        maintitle="Our Team"
        pagetitle="Meet the Team"
        background={bg}
      />
      <section className="content-inner-1 bg-light section-pattren1">
        <div className="container">
          <div className="row justify-content-center">
            {teamMembers.map((member, index) => (
              <div
                key={index}
                className={`col-12 col-sm-6 col-md-4 col-lg-${
                  teamMembers.length === 1
                    ? "12"
                    : teamMembers.length === 2
                    ? "6"
                    : teamMembers.length === 3
                    ? "4"
                    : "4"
                } d-flex`}
              >
                <TeamMemberCard {...member} />
              </div>
            ))}
          </div>
        </div>
        <img src={shape1} className="shape-1 move-1" alt="shape" />
        <img src={shape3} className="shape-3 move-1" alt="shape" />
        <img src={shape5} className="shape-4 rotating" alt="shape" />
        <img src={shape6} className="shape-5 rotating" alt="shape" />
      </section>
    </div>
  );
};

export default Team;
